$build: 1741772520390;
$cdn: "/joxi";
$cdnProject: undefined;
.preloader-index{
  position:fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index:4000;
  background: rgb(249, 251, 231);
  &.loaded{ display:none;}
}

$animation-duration: 1.7s;

.has-preloader {
  display: block;
  position: relative;
}

.preloader {
    position: absolute;
    z-index: 500;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: rgba(249, 251, 231, 0.5);

  &__ico {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -25px;
    margin-top: -6px;
    width: 50px;
    height: 12px;
    background: #AA69AC;
    border-radius: 12px;
    animation: load $animation-duration ease-in-out infinite;
    &:before, &:after {
      box-sizing: border-box;
      position: absolute;
      display: block;
      content: "";
      animation: load $animation-duration ease-in-out infinite;
      height: 12px;
      border-radius: 12px;
    }
    &::before {
      top: -20px;
      left: 10px;
      width: 40px;
      background: #FFC051;
      animation-delay: 0.2s;
    }
    &::after {
      bottom: -20px;
      width: 25px;
      background: #EA4642;
    }
  }
}

@keyframes load {
  0% {
    transform: translateX(40px);
  }
  50% {
    transform: translateX(-30px);
  }
  100% {
    transform: translateX(40px);
  }
}

