.preloader-index {
  z-index: 4000;
  background: #f9fbe7;
  width: 100%;
  height: 100%;
  position: fixed;
  inset: 0;
}

.preloader-index.loaded {
  display: none;
}

.has-preloader {
  display: block;
  position: relative;
}

.preloader {
  z-index: 500;
  background: #f9fbe780;
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}

.preloader__ico {
  background: #aa69ac;
  border-radius: 12px;
  width: 50px;
  height: 12px;
  margin-top: -6px;
  margin-left: -25px;
  animation: 1.7s ease-in-out infinite load;
  position: absolute;
  top: 50%;
  left: 50%;
}

.preloader__ico:before, .preloader__ico:after {
  box-sizing: border-box;
  content: "";
  border-radius: 12px;
  height: 12px;
  animation: 1.7s ease-in-out infinite load;
  display: block;
  position: absolute;
}

.preloader__ico:before {
  background: #ffc051;
  width: 40px;
  animation-delay: .2s;
  top: -20px;
  left: 10px;
}

.preloader__ico:after {
  background: #ea4642;
  width: 25px;
  bottom: -20px;
}

@keyframes load {
  0% {
    transform: translateX(40px);
  }

  50% {
    transform: translateX(-30px);
  }

  100% {
    transform: translateX(40px);
  }
}
/*# sourceMappingURL=index.2ae6a9d2.css.map */
